import ReactTooltip from 'react-tooltip';
import chien  from '../../images/dog.gif';
import chien2  from '../../images/dog2.gif';
import './dog.scss';
import {useEffect, useRef, useState} from "react";


function Dog({scene}) {

    const dogRef = useRef();
    const tooltipRef = useRef();
    const [load, setLoad] = useState(false);
    const [load2, setLoad2] = useState(false);
    const [stop, setStop] = useState(false);
    const [x, setX] = useState(-200);


    function dogLoaded(event) {
        setLoad(true);
    }
    function handleClick(event) {
        if(load2 && !stop){
            setStop(true)
            setTimeout(()=> {
                setStop(false)
                ReactTooltip.hide(dogRef.current)
            },8000)
        }
    }
    useEffect(()=> {
        const img = new Image();
        img.onload = () => {
            setLoad2(true);
        }
        img.src = chien2;
        setTimeout(()=> {
            dogRef.current.click();
            ReactTooltip.show(dogRef.current)
        },6000)
    },[])

    useEffect(()=> {

        setTimeout(()=> {
            if(!stop){
                if(x > scene.current.width) setX(-200);
                else setX(x+1);
            }
        },22)
    },[load,x,stop])

    return (
        <>
            <img data-tip data-for="hello" ref={dogRef} style={{transform: "translate3d("+x+"px,-100%,0)"}} onClick={handleClick} className={`dog-component ${load ? 'walking': ''} ${stop ? 'stop' :''}`} src={stop ? chien2 : chien} alt="Patate le chien" onLoad={dogLoaded}/>
            <ReactTooltip className="hello-tooltip" ref={tooltipRef} id="hello" type="light" place="top" effect="solid">
                <h4 className="text-lg text-semibold text-center">Bonjour,<br />Je suis Patate le chien!<br />Le site est en construction,<br />revenez vite ;)</h4>
            </ReactTooltip>
        </>
    );
}

export default Dog;