import scene  from './images/scene2.svg';
import './App.scss';
import {useEffect, useRef, useState} from "react";
import Dog from "./components/dog/dog";


function App() {

    const sceneRef = useRef();
    const appRef = useRef();
    const [minHeight, setMinHeight] = useState(0);
    const [offset, setOffset] = useState(0);

    function sceneLoaded(event) {
        setMinHeight(sceneRef.current.width  + (document.documentElement.clientHeight - document.documentElement.clientWidth));
    }
    useEffect(() => {
        document.getElementsByTagName('body')[0].onscroll = (e) => {
            setOffset(appRef.current.getBoundingClientRect().top);
        };
    }, [])

  return (
    <div ref={appRef} className="App" style={{minHeight: minHeight+"px"}}>
      <div className="scene" style={{left: offset+"px"}}>
        <img className="background" ref={sceneRef} src={scene} alt="L'univers de Quentin Laurent" onLoad={sceneLoaded} />
          <div className="layers">
              <Dog scene={sceneRef}></Dog>
          </div>
      </div>
    </div>
  );
}

export default App;
